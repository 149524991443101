@use '~@moved/ui/src/balazs/common/all' as *;

.container {
  width: 500px;
  padding: 50px 40px;
  border: 1px solid $border-color;
  border-radius: 28px;
  background: $white;
  @include breakpoint(xs) {
    border-radius: 0;
    padding: 40px 20px;
    width: 100%;
  }
}

.icon {
  display: inline-block;
}

.toggle_link {
  font-size: 12px;
}

.list_title {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
}

.form {
  width: 100%;
}
