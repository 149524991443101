@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Upcoming Jobs Panel
---------------------------------------- */

.container {

  position: relative;
  height: 100%;
}

.jobs_list {
  position: absolute;
  padding: 25px 30px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.day {
  margin-bottom: 60px;
  &:last-child {
    margin-bottom: 0;
  }
}

.day_header {
  font-size: $font-size-3;
  font-weight: $regular;
  color: $black-40;
  display: flex;
  margin-bottom: 15px;
}

.day_date {
  justify-content: flex-start;
  flex: 1 1 auto;
  // line-height: 36px;
  span {
    color: $black-90;
  }
}

.day_icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  border-radius: 20px;
  background-image: radial-gradient(circle at center , $black-20 50%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 0 0;
  background-position: 50% 50%;
  transition: background-size .3s, color .3s;
  padding: 6px;
  margin: -6px -6px -6px 0;
  user-select: none;
  &:hover {
    background-size: 200% 200%;
    color: $black-90;
  }
  &:focus, &:active {
    background-color: $black-20;
    background-image: none;
  }
}

.slot {
  background-image: linear-gradient($black-20 40%, rgba(255,255,255,0) 0%);
  background-position: right 10px top 1px;
  background-size: 2px 8px;
  background-repeat: repeat-y;
  position: relative;
  .spacer {
    padding-bottom: 25px;
  }
  &:last-child {
    .slot_footer {
      display: flex;
    }
  }
}

.slot_header, .slot_footer {
  font-weight: $med;
  padding: 10px 0;

  display: flex;
  background-color: $white;
}

.slot_header {
  margin-bottom: 5px;
}

.slot_footer {
  display: none;
}

.slot_label {
  flex: 1 1 auto;
  justify-content: flex-start;
}

.slot_time {
  flex: 1 1 auto;
  justify-content: flex-end;
  color: $black-30;
  text-align: right;
}

.job {
  font-size: $font-size-1;
  padding: 10px;
  margin: 0 -10px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: $black-10;
  }
  &:focus, &:active {
    background-color: $black-20;
  }
}

.no_jobs {
  font-style: italic;
}
