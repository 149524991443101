@use '~@moved/ui/src/balazs/common/all' as *;

.profile_link {
  display: block;
  position: relative;
  margin: 0 24px 24px;
  border-top: 1px solid $border-color;
  line-height: 16px;
  padding-top: 20px;
  font-size: 12px;
  &:hover {
    .label { color: $primary; }
    .icon { color: $primary; }
  }
}

.label {
  display: block;
  color: $black-40;
  transition: color 0.3s;
}

.name {
  color: $black-90;
  font-size: 14px;
  line-height: 24px;
}

.arrow {
  position: absolute;
  bottom: 22px;
  right: 0px;
  line-height: 0;
}

.icon {
  transition: color 0.3s;
  color: $black-30;
  --color-1: currentColor;
}
