@use '~@moved/ui/src/balazs/common/all' as *;

.address_row {
  font-size: $font-size-2;
  line-height: 17px;
  color: $black-90;
  padding-bottom: 35px;
  padding-left: 30px;
  position: relative;
  background-image: linear-gradient($black-20 0%, $black-20 100%);
  background-position: left 3px top 0px;
  background-size: 1px 10px;
  background-repeat: repeat-y;
  &:last-child {
    padding-bottom: 0;
    background-image: none;
  }
  &::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    height: 7px;
    width: 7px;
    background: $blue-30;
    border-radius: 999em;
    outline: 5px solid #fff;
    z-index: 2;
  }
}

.address_items {
  display: flex;
  margin-top: 15px;
}

.address_item {
  flex: 0 0 auto;
  margin-right: 25px;
  h6 {
    margin-bottom: 10px;
  }
  &:last-child {
    margin-right: 0;
  }
}
