@use '~@moved/ui/src/balazs/common/all' as *;

// Layout Wrapper
.content {
  flex: 1 1 auto;
  margin: 50px 50px 50px 50px;
  position: relative;
  @include breakpoint(xs) {
    margin: 0;
    background-color: $bg-light-opaque;
    align-self: flex-start;
  }
}

// Table Columns
@mixin stickyHeader {
  position: sticky;
  position: -webkit-sticky; // still needed in 2020 (ugh)
  z-index: 10;
  top: 0px;
  background: $white;
}
.customer_column {
  @include stickyHeader;
  width: 30%;
}
.move_date_column,
.rate_column,
.revenue_column,
.status_column {
  @include stickyHeader;
  width: 15%;
}
.action_column {
  @include stickyHeader;
  width: auto;
}

// Mobile Card List
.customer_field {
  color: $black-90;
  font-size: $font-size-3;
}
.move_date_field {
  color: $black-40;
  flex: 1 1 auto;
}
.rate_field {
  color: $black-40;
  flex: 0 0 auto;
}
.revenue_field {
  color: $black-90;
  flex: 0 0 auto;
  margin-left: 30px;
}
.action_field {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: -5px;
  padding-top: 10px;
  border-top: 1px solid $border-color;
  font-size: $font-size-3;
}

// Status Chicklets
.status_booked,
.status_in-transit,
.status_delivered,
.status_processing {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 15px;
  border: solid 1px $green-40;
  border-radius: 999em;
  transition: all 0.3s;
}
.status_booked {
  color: $blue-30;
  background: $white;
  border-color: $blue-30;
}
.status_in-transit {
  color: $white;
  background: $blue-30;
  border-color: $blue-30;
}
.status_delivered {
  color: $white;
  background: $green-40;
  border-color: $green-40;
}
.status_processing {
  color: $white;
  background: $orange-40;
  border-color: $orange-40;
}

// Pagination Layout
.pager {
  margin: 30px auto;
  @include breakpoint(xs) {
    margin: 10px auto 25px;
  }
}
