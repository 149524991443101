@use '~@moved/ui/src/balazs/common/all' as *;
@use './variables';
/* ----------------------------------------
    Feature template layout
---------------------------------------- */

.template {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: variables.$headerHeight;
}

.content {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
}
