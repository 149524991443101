@use '~@moved/ui/src/balazs/common/all' as *;

.finalize {
  width: 550px;
  padding: 0 30px;
  p {
    font-size: $font-size-2;
    line-height: 1.5;
  }
  @include breakpoint(xs) {
    width: auto;
    padding: 0;
  }
}

.finalize_form {
  width: 300px;
  @include breakpoint(xs) {
    max-width: 100%;
  }
}
