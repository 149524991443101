@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Weekly Calendar View
---------------------------------------- */

.week_row {
  display: flex;
  height: 100%;
}

// Column: Normal, Today, Hover Border Colors
$normal: $black-20;
$normalVertical: $black-30;
$active: $black-30;
$today: $blue-20;
$hover: $black-30;

// Cell: Hover & Active Colors
$cellHover: $orange-40;
$cellHoverBG: $orange-10;
$cellActiveBG: $orange-20;

/*  -~-~-~-~-~-   START Columns   -~-~-~-~-~-  */

.day_column, .key_column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.key_column {
  flex: 0 0 auto;
  width: 60px;
}

.day_column {
  flex: 1 1 0;
  margin-left: -1px;

  border-left: 1px solid $normalVertical;
  border-right: 1px solid $normalVertical;

  position: relative;
  z-index: 1;

  &:first-child {
    margin-left: 0;
  }

  &.today {
    z-index: 4;
    border-color: $today;
  }
  &:hover {
    border-color: $hover;
    z-index: 5;
  }

  @include breakpoint(xs) {
    border-left-width: 0;
    border-right-width: 0;
  }
}

/*  -~-~-~-~-~-   END Columns   -~-~-~-~-~-~-  */


/*  -~-~-~-~-~-   START Header   -~-~-~-~-~-~-  */

.day_header, .key_header {
  text-align: center;
  height: 80px;
  padding: 15px;
  flex: 0 0 auto;
}

.day_header {
  border-bottom: 1px solid $black-20;
  background-color: $black-10;
  z-index: 2;
  display: flex;
  @include breakpoint([sm, md, lg, xlg]) {
    .day_column:hover & {
      background-color: $black-20;
      border-bottom-color: $black-20;
    }
  }
}

.day_of_week {
  color: $black-40;
  text-transform: uppercase;
  font-size: $font-size-0;
  margin: 0 0 3px;
  .today & {
    color: $blue-50;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  .arrow {
    color: $black-40;
    &:hover {
      color: $black-90;
    }
  }
}

.day_of_month {
  color: $black-90;
  font-weight: $med;
  font-size: $font-size-5;
  line-height: 30px;
  span {
    border-radius: 40px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    // background-color: $white;
  }
  .today & {
    color: $white;
    span {
      background-color: $blue-50;
    }
  }
}

/*  -~-~-~-~-~-~-   END Header   -~-~-~-~-~-~-  */


/*  -~-~-~-~-~-   START Slots   -~-~-~-~-~-~-  */

.slot, .key_slot {
  flex: 1 0 auto;
  margin: -1px -1px 0 -1px;
  min-height: 170px;
  position: relative;

  &:last-child {
    margin-bottom: 15px;
    @include breakpoint(xs) {
      margin-bottom: 0;
    }
  }
}

.key_slot {
  z-index: 2;
  border-top: 1px solid $normal;
  border-bottom: 1px solid $normal;
}

.slot {
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out 0s;

  border: 1px solid $normal;
  border-left-color: $normalVertical;
  border-right-color: $normalVertical;

  .today & {
    border-left-color: $today;
    border-right-color: $today;
  }
  .day_column:hover & {
    border-left-color: $hover;
    border-right-color: $hover;
  }
  &:hover, .day_column:hover &:hover {
    border-color: $cellHover;
    background-color: $cellHoverBG;
    outline: 1px solid $white;
    outline-offset: -2px;
    z-index: 6;
  }
  &:active, .day_column:hover &:active {
    outline: 3px solid $white;
    outline-offset: -4px;
    background-color: $cellActiveBG;
    transition: background-color 0s ease-in-out 0s;
  }
  @include breakpoint(xs) {
    min-height: 60px;
    border-left-width: 0;
    border-right-width: 0;
    margin-left: 0;
    margin-right: 0;
    &.slot_capacity {
      padding-bottom: 40px;
    }
  }
}

.slot_header {
  display: none;
  padding: 15px 15px;
  justify-content: space-between;
  h6 {
    color: $black-90;
    font-weight: $med;
    padding-right: 10px;
    span {
      color: $black-30;
      font-weight: $regular;
      margin-left: 5px;
    }
  }
  @include breakpoint(xs) {
    display: flex;
  }
}

.slot_unavailable {
  background-image: url('../images/diagonal-white.png');
  background-position: center;
  background-attachment: fixed;
  background-repeat: repeat;
  background-color: $red-10;
  .day_column:hover &:hover {
    background-color: $red-20;
  }
  .day_column:hover &:active {
    background-color: $red-30;
  }
}

/*  -~-~-~-~-~-~-   END Slots   -~-~-~-~-~-~-  */


/*  -~-~-~-~-~-   START Slots Content   -~-~-~-~-~-~-  */

.slot_content, .key_slot_content {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.slot_content {
  padding: 6px;
  z-index: 1;
}

.key_slot_content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: $font-size-0;
  align-items: center;
  justify-content: center;
  label {
    width: 100%;
    display: block;
    text-align: center;
  }
  .key_times {
    color: $black-30;
  }
}

/*  -~-~-~-~-~-~-   END Slots Content   -~-~-~-~-~-~-  */


/* ----------------------------------------
    View Specific Slot Content
---------------------------------------- */

.availability_txt {
  flex: 1 1 auto;
  color: $black-40;
  font-size: $font-size-0;
  font-style: italic;
  border-radius: 2px;
  padding: 8px 10px;
  margin-bottom: 5px;
  background-color: rgba(255, 255, 255, 0.4)
}

.price {
  z-index: 6;
  position: absolute;
  background-color: $black-40;
  transform: rotate(45deg);
  height: 60px;
  width: 60px;
  top: -30px;
  right: -30px;
  color: $white;
  transition: background-color 0.3s ease-in-out 0s;
  span {
    font-size: $font-size-1;
    line-height: $font-size-1;
    font-weight: $semibold;
    position: absolute;
    text-align: center;
    bottom: 3px;
    left: 0;
    right: 0;
  }
  &.price_positive {
    background-color: $green-40;
  }
  &.price_negative {
    background-color: $red-40;
  }
}

// Large Price Circles
.price_large {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .price_num {
    font-size: $font-size-5;
    font-weight: $semibold;
    border-radius: 200px;
    background-color: $black-40;
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    color: $white;
    margin-bottom: 10px;
    transition: background-color 0.3s ease-in-out 0s;
  }
  .price_txt {
    font-size: $font-size-3;
    font-weight: $med;
  }
  &.price_positive {
    color: $green-40;
    .price_num {
      background-color: $green-40;
    }
    .price_txt {
      color: $green-30;
    }
  }
  &.price_negative {
    color: $red-40;
    .price_num {
      background-color: $red-40;
    }
    .price_txt {
      color: $red-30;
    }
  }
}

/*  -~-~-~-~-~-   START Jobs List   -~-~-~-~-~-  */
.jobs {
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  max-height: calc(100% - 55px);
  overflow-x: hidden;
  overflow-y: auto;
  @include breakpoint(xs) {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    padding: 0 15px 15px;
    max-height: none;
  }
}
.job {
  border-radius: 2px;
  color: $blue-50;
  background-color: $blue-10;
  padding: 8px 10px;
  font-size: $font-size-1;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  z-index: 2;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: $blue-20;
  }
  &:focus, &:active {
    color: $blue-10;
    background-color: $blue-50;
  }
  @include breakpoint(xs) {
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 8px;
    font-size: $font-size-2;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
/*  -~-~-~-~-~-   END Jobs List   -~-~-~-~-~-  */


/*  -~-~-~-   START Capacity Bar + Text   -~-~-~-  */
.trucks {
  padding: 6px;
  @include breakpoint(xs) {
    padding: 0 15px 15px;
  }
}

.remaining_trucks {
  color: $black-40;
  font-size: $font-size-1;
  font-weight: $regular;
  padding: 8px 10px;
  border-radius: 2px;
  background-color: $black-10;
  span {
    color: $white;
    background-color: $black-40;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    display: inline-block;
    font-weight: $med;
    text-align: center;
    margin-right: 3px;
  }
}

.capacity {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  padding: 8px;
  @include breakpoint(xs) {
    padding: 15px;
  }
}

.capacity_txt {
  color: $black-40;
  font-size: $font-size-0;
  font-weight: $regular;
  user-select: none;
  margin-bottom: 2px;
  z-index: 2;
  position: relative;
  display: flex;
  span {
    font-size: $font-size-1;
    font-weight: $semibold;
  }
}

i.capacity_icon {
  font-size: 16px;
  line-height: 16px;
  color: $black-30;
  display: none;
}

.capacity_bar {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  padding: 0px 10px;
  height: 5px;
  background-color: rgba(197, 201, 207, 0.2);
}

.capacity_bar_fill {
  position: absolute;
  border-radius: 30px;
  background-color: rgba(197, 201, 207, 1);
  z-index: 1;
  left: 0;
  top: 0;
  right: auto;
  bottom: 0;
  width: 0;
  transition: width 0.3s ease-in-out 0s;
}
/*  -~-~-~-   End Capacity Bar + Text   -~-~-~-  */
