@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Job Details modal
---------------------------------------- */

.job_modal {
  width: 550px;
  @include breakpoint(xs) {
    width: auto;
  }
}

.status_pill {
  padding: 2px 12px;
  font-size: $font-size-1;
  font-weight: $med;
  background-color: $black-40;
  border: 1px solid $black-40;
  color: #fff;
  border-radius: 15px;
  &.pending {
    background-color: transparent;
    border-color: $green-40;
    color: $green-40;
  }
  &.booked {
    background-color: transparent;
    border-color: $blue-30;
    color: $blue-30;
  }
  &.in-transit {
    background-color: $blue-30;
    border-color: $blue-30;
  }
  &.delivered {
    background-color: $green-40;
    border-color: $green-40;
  }
  &.processing {
    background-color: $orange-40;
    border-color: $orange-40;
  }
}

.deliver_button {
  font-size: $font-size-2;
}
