@use '~@moved/ui/src/balazs/common/all' as *;
@use './variables';
/* ----------------------------------------
    Vendor App Header
---------------------------------------- */

.header {
  padding: 12px 24px;
  background-color: $white;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.07);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 110;
  height: variables.$headerHeight;
  @include breakpoint(xs) {
    padding: 12px 15px;
  }
}

.hamburger_section {
  display: flex;
  justify-content: flex-start;
  flex: 0 0 auto;
  @include breakpoint(xs) {
    width: 110px;
  }
}

.logo_section {
  flex: 0 0 auto;
  @include breakpoint(xs) {
    justify-content: center;
    display: flex;
    flex: 1 1 auto;
  }
}

.content_section {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  @include breakpoint(xs) {
    flex: 0 0 auto;
    width: 110px;
  }
}

.hamburger_hldr {
  line-height: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px 35px 5px 0;
}

.mvd_logo {
  max-width: 90px;
  margin-right: 30px;
  @include breakpoint(xs) {
    display: none;
  }
}

.mvd_icon {
  display: none;
  width: 40px;
  @include breakpoint(xs) {
    display: block;
  }
}

.left_content {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  align-self: center;
  border-left: 1px solid $black-30;
  height: 28px;
  line-height: 28px;
  padding-left: 25px;
  font-size: $font-size-3;
}
