@use '~@moved/ui/src/balazs/common/all' as *;

.stripe-banner {
  background: $bg-light;
  border-bottom: 1px solid $border-color;
  text-align: center;
  line-height: 36px;
  padding: 10px 0;
  @include breakpoint(xs) {
    padding: 0;
    border-bottom: 0;
  }

  .button {
    display: inline-block;
    vertical-align: top;
    margin-left: 25px;
    @include breakpoint(xs) {
      margin: 0;
      display: block;
      max-width: none;
      border-radius: 0;
    }
  }

}
