@use '~@moved/ui/src/balazs/common/all' as *;

.inventory_title {
  display: flex;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
  .arrow {
    margin-left: 8px;
    @include arrow(5px,$black-40,'right');
    transition: transform 0.3s linear;
    &.arrow_open {
      transform: rotate(90deg);
    }
  }
  &:hover {
    color: $black-90;
    .arrow {
      @include arrow(5px,$black-90,'right');
    }
  }
}

.inventory_list {
  overflow-y: auto;
  max-height: 600px;
  margin-top: 20px;
  @include breakpoint(xs) {
    max-height: none;
  }
}

.inventory_content {
  background-color: $black-10;
  padding: 15px;
  border-radius: 3px;
}
