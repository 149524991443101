@use '~@moved/ui/src/balazs/common/all' as *;

/* ----------------------------------------
    Feature template layout
---------------------------------------- */

.main_area {
  flex: 1 1 auto;
}

.side_area {
  width: 300px;
  display: flex;
  flex-direction: column;
  @include breakpoint([xs,sm,md]) {
    width: 260px;
  }
}

.mobile_column {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.mobile_top {
  border-bottom: 1px solid $black-20;
  height: 61px;
  width: 100%;
  position: fixed;
  z-index: 98;
  overflow: hidden;
  transition: height 0s linear;
  &.open {
    height: 373px;
    transition: height 0.3s ease-in;
  }
}

.mobile_top_spacer {
  height: 61px;
  flex: 0 0 auto;
}

.mobile_calendar_header {
  background-color: $white;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  h4 {
    font-size: $font-size-3;
    font-weight: $regular;
    line-height: 40px;
  }
}

.mobile_calendar {
  background-color: $black-10;
  padding: 15px;
}

.mobile_btn {
  background-color: $black-20;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black-90;
  &.mobile_btn_active {
    background-color: $blue-50;
    color: $white;
  }
}

//  ---- Monthly Calendar Holder ----
.month_calendar {
  padding: 25px 30px;
  border-bottom: 1px solid $black-20;
}

/* ----------------------------------------
    Slot Edit Form Widget
---------------------------------------- */

.slot_edit {
  width: 280px;
  position: relative;
  @include breakpoint(xs) {
    width: auto;
    margin: -25px;
  }
}

.confirm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .slot_edit_title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid $black-20;

    padding: 15px 20px;
    margin: -5px -5px 0 -5px;
    span {
      font-size: $font-size-2;
      color: $black-90;
      font-weight: $med;
    }
    span.date {
      color: $black-40;
      font-weight: $regular;
    }
    span.close {
      display: inline-block;
      height: 16px;
      width: 16px;
      line-height: 13px;
      font-size: 20px;
      margin-left: 10px;
      text-align: center;
      cursor: pointer;
      color: $black-40;
      background-image: radial-gradient(circle at center , $black-20 50%, transparent 50%);
      background-repeat: no-repeat;
      background-size: 0 0;
      background-position: 50% 50%;
      transition: background-size .3s, color .3s;
      border-radius: 100px;
      user-select: none;
      &:hover {
        background-size: 200% 200%;
        color: $black-90;
      }
      &:focus, &:active {
        background-color: $black-20;
        background-image: none;
      }
    }

  }

  .slot_edit_form {
    padding: 15px;
  }

  .slot_edit_control {
    border-radius: 0 0 3px 3px;
    padding: 0 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      box-shadow: 0 3px 10px rgba(0,0,0,0.15);
      @include breakpoint([sm, md, lg, xlg]) {
        font-size: $font-size-1;
        padding: 0;
        height: 32px;
        line-height: 32px;
      }
    }
  }
