@use '~@moved/ui/src/balazs/common/all' as *;
@use './variables';

.template {
  min-height: 100vh;
  background: $bg-light;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.header {
  height: variables.$headerHeight;
  padding: 20px 50px;
  flex: 0 0 auto;
}
.logo {
  height: 20px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}
